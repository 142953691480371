import * as React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { FormattedMessage, useIntl } from "react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import RichText from "../components/rich-text";
import Link from "../components/link";

const NotFoundText = () => {
  const { locale } = useIntl();

  if (locale === "en") {
    return (
      <p>
        The page you are looking for is unfortunately no longer available or has
        been moved. Do not hesitate to visit{" "}
        <Link href="/faq/">our questions section</Link> or to{" "}
        <Link href="/contact/">contact our team</Link>. See you soon!
      </p>
    );
  }

  return (
    <p>
      La page que vous recherchez n’est malheureusement plus disponible ou a été
      déplacée. N’hésitez pas à visiter{" "}
      <Link href="/faq/">notre rubrique questions</Link> ou à{" "}
      <Link href="/contact/">contacter notre équipe</Link>. À très vite !
    </p>
  );
};

const NotFoundPage = () => {
  const { formatMessage } = useIntl();
  const title = formatMessage({ id: "notFoundTitle" });

  return (
    <Layout>
      <Seo title={title} />
      <Hero title={title} />
      <RichText>
        <h2 className="h3">
          <FormattedMessage id="notFoundHeading" />
        </h2>
        <NotFoundText />
      </RichText>
      <StaticImage src="../assets/mountains-2.jpg" quality={80} alt="" />
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($locale: String!) {
    translations: contentfulTranslations(node_locale: { eq: $locale }) {
      translations {
        key
        value
      }
    }
  }
`;
